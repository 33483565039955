.cq__container {
  display: grid;
  gap: 16px; 
  grid-template-columns: repeat(2, 1fr);

  /* using container queries to add a third column if the container is large enough
   */
  @container (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr); 
  }
}

/* This operates as a context 
 * add this as a parent to use container queries within
 **/
.cq__container-parent {
  container-type: inline-size;
}
